/* ---  Subscribe --- */

.section-subscribe {
    width: 100% !important;
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    
}

.intro-subscribe{
    width: 45% !important;
}

section article.intro-subscribe{
    display: block !important;
}

.form {
    width: 45% !important;
    margin: auto;
}

.form .champ {
    display: flex;
    flex-flow: column wrap;
}

.form .champ:nth-child(3),
.champ:nth-child(4) {
    flex-grow: 1;
    width: 100% !important;
}

#display-mobile{
    display: none;
}

#display-desktop{
    display: block;
}

::placeholder {
    font-family: bilo, sans-serif;
    font-size: 1rem;
    color: rgb(177, 177, 177);
}


a#btn-subscribe {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.7rem;
    color: white;
    background-color: #353096;
    border-radius: 8px;
}

a#btn-subscribe:hover{
    background-color: #FF896C; 
}


input,
select,
textarea {
    border: 0.1rem solid grey;
    background-color: white !important;
    color: grey;
    border-radius: 3px;
    padding: 0.2rem;
}

label {
    display: inline-block;
    text-align: left;
    padding: 0 0.5em 0.5rem 0;
    margin-top: 1rem;

}

label:hover {
    cursor: pointer;
}


input[type=submit] {
    /* cible le bouton input de type submit pour l'alignement à droite */
    float: right;
    border-style: solid;
    border-width: 1px;
    border-color: #002F2F;
}

input[type=text],
input[type=email],
input[type=number],
input[type=date] {
    width: 100%;
}

/*------------ Gestion du placeholder ----------------- */


/* Ok pour FFx, Chrome, Safari et Opéra */
input::placeholder {
    color: #353096;
    font-style: italic;
}


label.obligatoire::before {
    content: "* ";
    /* ajout automatique d'un astérisque devant les champs obligatoires */
    color: #353096;
}


/* --------------------- Gestion des éléments réactifs au clic ----------------------------- */

input:hover,
input:focus,
select:hover {
    outline: 0.1rem solid #353096;
    /* La propriété outline css ne réagissent pas comme les bordures, ces contours viennent au dessus de l'élément et n'interviennent pas dans la taille de l'élément. */
}



/* --------------------- Gestion des éléments invalides --------------------------- */

/*La pseudo-classe :invalid représente tout élément <input> dont la validation du contenu échoue en regard du type de donné attendu. Ceci permet de donner aisément une apparence aux champs non valides aidant l'utilisateur à identifier et corriger les erreurs. */

input:invalid {
    background-color: orange;
}

/*La pseudo-classe CSS :valid représente tout élément <input> dont la validation du contenu s'effectue correctement en regard du type de donné attendu. Ceci permet de donner aisément une apparence aux champs de formulaire correctement remplis par l'utilisateur.(Webkit) */

input:valid {
    background-color: silver;
}


/* ---  Fin Subscribe --- */

@media only screen and (max-width: 900px) {

    #display-mobile{
        display: block;
        padding-top: 2rem;
    }

    #display-desktop{
        display: none;
    }

    .home-subscribe h1{
        font-size: 4rem;
    }

    .section-subscribe {
        flex-flow: column wrap;
        
    }

    section.home-subscribe{
        margin: 0;
    }
    
    .form, .intro-subscribe {
        width: 100% !important;
    }

    section article.intro-subscribe{
        margin-left: 0rem;
    }
}

