/* --- Section Home --- */

.home-nuton{
    background-image: url(../img/nutonsImg.jpg);
}

.home-lutin{
    background-image: url(../img/enfants.jpg);
}

.home-guide{
    background-image: url(../img/fire.jpg);
}

.home-pi{
    background-image: url(../img/chain.jpg);
}

.home-subscribe{
    background-image: url(../img/subscribe.jpg) !important;
    background-position: 50% 100% !important; 
}

.home-blog{
    background-image: url(../img/newspaper.jpg) !important;
    background-position: 50% 100% !important; 
}

.section-home{
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}


.home-subscribe{
    background-image: url(../img/chain.jpg);
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    height: 50vh;
    width: 100vw !important;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
}

.section-home h1{
    color: white;
    font-size: 8rem;
    text-shadow: 1px 1px 1px rgb(182, 182, 182), 0 0 1em rgb(182, 182, 182) ;
    text-align: center;
}

.section-home h2{
    color: white;
}

/* --- Fin Section Home --- */

/* --- Section Intro --- */

.intro-nuton{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.intro-nuton article:nth-child(1){
    width: 35%;
}

.intro-nuton article:nth-child(2){
    width: 60%;
}

/* --- Fin Section Intro --- */

/* --- Section Staff --- */

.section-staff ul{
    display: flex;
    flex-flow: row wrap;
}

.section-staff ul li{
    width: 45%;
}

/* --- Fin Section Staff --- */

.icone-section{
    color: gray;
    font-size: 6rem;
    color: #eec094;
    margin-bottom: 1rem;
}

.icone-scout{
    color:#9aca9a;
}

.icone-loup{
    color:#ed7d79;
}

/* --- Rendez-vous --- */

#activity-mobile{
    display: none;
}

.activity{
    display: flex;
    flex-flow: row wrap;
    justify-content:space-between;
}

.activity:hover{
    background-color: white;
}

.activity h3, .activity p{
    width: 32%;
    text-align: center;
}

@media only screen and (max-width: 740px) {

    .section-home h1 {
        font-size: 5rem;
        text-align: center;
    }

    .intro-nuton article{
        width: 100% !important;
        flex-flow: row wrap;
    }

    section article{
        margin-left: -2rem;
    }

    #activity-mobile{
        display: block;
    }

    #activity-desktop{
        display: none;
    }

    .activity.list-item{
        display: block;
        border-bottom: 0.1rem solid gray;
        margin-left:0;
    }

    .activity.list-item li{
        list-style: none;
        padding-left: 0.5rem;
    }
}

