@import url("https://use.typekit.net/rcn8nzq.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.html {
    background-color: rgba(204, 204, 204, 0.29);
    overflow-x: hidden; 
}

h2,
h3,
h4,
p, li {
    color: grey;
}

h1#main-title {
    font-size: 8rem;
    line-height: 9rem;
    font-family: bilo, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3rem;
}

h1 {
    font-size: 5rem;
    line-height: 7rem;
    font-family: bilo, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3rem;
}

h2 {
    font-size: 3.5rem;
    line-height: 3.7rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 2rem;
}

h3 {
    font-size: 2rem;
    line-height: 2rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    margin-bottom: 2rem;
}

h4 {
    font-size: 1.7rem;
    line-height: 2rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    margin-bottom: 0.7rem;
}

p,
.list-item,
label, #btn-subscribe {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-family: bilo, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: gray;
    padding-bottom: 0.5rem;
}

.list-item {
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.list-item li {
    padding-bottom: 0.4rem;
}

a:hover {
    cursor: pointer;
}

.btn-text {
    color: #353096;
    border: none;
    font-family: bilo, sans-serif;
}

a {
    text-decoration: none;
}


.btn-text:hover {
    color: #FF896C;
    border-bottom: 0.1rem solid #FF896C;
}

.btn {
    font-size: 1.8rem;
    line-height: 1.7rem;
    font-family: bilo, sans-serif;
    font-weight: 300;
    color: white;
    background-color: #353096;
    padding: 0.5rem 3.7rem;
    border-radius: 8px;
}

.btn:hover {
    color: black;
    background-color: #FF896C;
}

.banner-text {
    text-align: center;
    font-weight: 300;
    padding-top: 5rem;
}

.text-center {
    text-align: center;
}

section {
    margin-bottom: 5rem;
}


/* ---  Header --- */

#hamburger {
    display: none;
    width: 2rem;
    height: 1.7rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
}

.burger {
    display: none;
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: gray !important;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

header#navArea {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-family: bilo, sans-serif;
    padding-top: 3rem;
    position: fixed;
    left: 10%;
    width: 80%;
    transition: background-color 7s;
    transition: margin-top 7s;
}

#slogan {
    color: white;
    font-weight: 500;
}

header figure p {
    font-size: 1rem;
}

header figure a {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
}

header figure img {
    height: 8rem;
    width: auto;
}

header ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    max-width: 700px;
}

.nav-reseau {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    /* padding-left: 5%; */
}

.nav-reseau img {
    width: 3rem;
}

.nav-reseau a:nth-child(2) img{
    width: 3.7rem;
}

.nav-reseau a:nth-child(3) {
    margin-top: 0.3rem;
    margin-left: 0.8rem;
}

header ul li {
    list-style: none;
}

header ul li {
    position: relative;
}

header ul li.deroulant::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid white;
    position: absolute;
    top: 40%;
    right: -12px;
}

#navArea.is-sticky ul li.deroulant::after {
    border-top: 8px solid gray !important;
}

.hamburgerbox, .popupModifybox {
    display: none;
}

.sous .icone {
    display: inline;
    font-size: 1rem;
    margin-right: 0.5rem;
}

header ul li a {
    margin-top: 0.5rem;
}

header#navArea ul li a::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: white;
    transition: width .3s;
}

header#navArea.is-sticky ul li a::after {
    background: gray;
}

header#navArea ul li:hover a::after {
    width: 110%;
}

header#navArea ul.sous li a::after,header#navArea ul li.nav-reseau a::after {
    content: '';
    display: none;
}
/* sous menu */

#sectiononpen {
    display: block;
}

#sectionclose {
    display: none;
}

li a.deroulant {
    position: relative;
}

#adminopen,
#sectionopen {
    display: block;
    display: flex;
    flex-flow: column wrap !important;
    align-items: baseline;
    position: absolute;
    width: 15rem !important;
    border-radius: 10px;
    margin-top: 1rem;
    margin-left: -3.2rem;
    background-color: white;
    z-index: 300;
    animation: scrollMenu 0.2s ease-in-out;
    opacity: 1;
}

#adminopen::before,
#sectionopen::before {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: white;
    position: absolute;
    top: -7px;
    left: 35%;
    transform: rotate(45deg);
    z-index: -1;
}

#adminopen li,
#sectionopen li {
    padding: 1rem;
    width: 100%;
}

#adminopen li:hover,
#sectionopen li:hover {
    background-color: #35309639;
}

#adminclose {
    display: none;
}


header #navclose li a {
    text-decoration: none;
    color: white;
    font-weight: 400;
}

header #navclose li a.sous-link {
    text-decoration: none;
    color: gray;
}


/* fin sous menu */

/* menu scroll */
#navArea{
    display: block;
}

@keyframes scrollMenu{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
#navArea.is-sticky {
    animation: scrollMenu 0.4s ease-in-out;
     z-index: 300;
    background-color: rgb(244, 244, 244);
    margin-top: 0rem;
    position: fixed;
    width: 100vw;
    padding: 1% 15%;
    left: 0;
    opacity: 1;
    box-shadow: 0px 4px 12px 5px rgba(0,0,0,0.2);
} 

#navArea.is-sticky a {
    color: gray;
}

#navArea.is-sticky img {
    height: 5rem;
    width: auto;
}

#navArea.is-sticky .nav-reseau a img {
    width: 3rem !important;
}

#navArea.is-sticky .nav-reseau a:nth-child(2) img {
    width: 3.7rem !important;
}

/* fin menu scroll */


/* ---  Fin Header --- */

/* ---  Main --- */

main {
    width: 75%;
    margin: auto;
}

/* ---  Section-Home --- */


.homepage {
    padding: 15rem 10rem;
    min-height: 100vh !important;
    background-image: url(../img/pilotis.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 40%;
    text-align: center;
    color: white;
    margin-bottom: 0;
}

/* cloud */

#cloud{
    display: none;
    
}
/* fin cloud */

.homepage h1 em {
    color: rgb(180, 180, 180);
    font-style: normal;
}

.homepage p em,
.section-home p em {
    font-family: bilo, sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 2rem;
    color: white;
}

/* ---  Fin Section-Home --- */

/* ---  Fin Section-Sections-Valeurs --- */

section article {
    padding: 1rem;
}

section article figure {
    padding: 1rem;
}

img {
    width: 15rem;
    height: auto;
}

section article {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: strech;
    margin-bottom: 0.5rem;
}

.background-white {
    background-color: white;
    margin: 1rem;
}

section article figure {
    width: 30%;
}

section article figure.four {
    width: 20%;
}

section article figure {
    text-align: center;
}

section article figure div.image-background {
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
}

section article figure:nth-child(1) div.image-background {
    background-image: url(../img/respect.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

section article figure:nth-child(2) div.image-background {
    background-image: url(../img/entraide.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

section article figure:nth-child(3) div.image-background {
    background-image: url(../img/proactive.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section-sections article figure {
    transition: transform .2s;
}

.section-sections article figure:hover {
    transform: scale(1.05);
}

/* ---  Fin Main --- */


/* ---  Footer --- */

footer {
    background-color: white;
    text-align: center;
    padding: 2.3rem 7rem;
}

/* ---  Fin Footer --- */

@media only screen and (max-width: 1300px) {
    header {
        left: 7%;
        width: 90%;
    }
    header#navArea{
        width: 100vw;
        padding: 1% 5% !important;
        left: 0;
    }
    header#navArea.is-sticky {
        z-index: 300;
        background-color: rgb(244, 244, 244);
        width: 100vw;
        padding: 1% 5% !important;
        left: 0;
    }
   
}

@media only screen and (max-width: 1000px) {
    main {
        width: 90%;
    }
    .hamburgerbox,
    .hamburger,
    .burger {
        display: block;
    }

    #hamburger {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
        margin-right: 1rem;
    }

    .hamburger {
        padding-top: 10px;
        margin-right: 10px;
        z-index: 600;
    }

    header {
        position: absolute;
    }


    #navopen {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        width: 100vw;
        margin-top: 0px;
        border-radius: 10px;
        padding: 6rem 1.5rem;
        color: gray;
        font-size: 2rem;
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    #navopen ul {
        height: 70vh;
    }

    header ul li {
        padding: 1rem;
    }

    header ul.sous li {
        padding: 1rem;
        width: 80vw;
        border-bottom: none !important;
    }

    header ul li {
        list-style: none;
    }

    #navclose {
        display: none;
    }

    header ul li.deroulant::after {
        top: 50%;
        right: 20%;
    }

    ul li.deroulant::after {
        border-top: 8px solid gray !important;
    }

    #navArea.is-sticky ul li:nth-child(2)::after,
    #navArea.is-sticky ul li:nth-child(4)::after {
        border-top: 8px solid gray !important;
    }

    li:last-child{
        border-bottom: none !important;
    }
    /* sous menu */
    
    #adminopen,
    #sectionopen {
        display: block;
        display: flex;
        flex-flow: column wrap !important;
        align-items: baseline;
        position: inherit;
        width: 90% !important;
        padding:0.2rem;
        font-size: 1.2rem;
        height: auto;
        left: 5%;
        right: 5%;
        margin: 0;
    }


    #adminopen::before,
    #sectionopen::before {
        display: none;
    }
    
    #adminopen li,
    #sectionopen li {
        padding: 1rem;
        width: 100%;
    }
    
    #adminopen li:hover,
    #sectionopen li:hover {
        background-color: #35309639;
    }

        
    header #navclose li a {
        text-decoration: none;
        color: white;
        font-weight: 400;
    }
    
    header #navclose li a.sous-link {
        text-decoration: none;
        color: gray;
    }
    
    header .sous li a::after {
        content: '';
        display: none;
    }
    /* fin sous menu */
}

@media only screen and (max-width: 700px) {
    main {
        width: 92%;
    }

    header#navArea {
        margin-top: 0rem;
        padding: 1rem;
        width: 90%;
        left: 5%;
    }

    #slogan {
        display: none;
    }


    h1#main-title {
        font-size: 3rem;
        line-height: 4rem;
        margin-bottom: 3rem;
    }

    .homepage {
        padding: 12rem 2rem;
        background-size: cover;
    }

    #navopen {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 2rem;
        height: 100vh;
        width: 100%;
        right: 0;
        text-align: center;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    header ul {
        padding: 6rem 0.2rem;
    }

    header ul li {
        border-bottom: 0.1rem solid #353096 !important;
        padding: 1.4rem 0;
    }

    header ul li:last-child {
        border-bottom: none !important;
        padding: 1.4rem 0;
    }

    header ul li a {
        color: gray;
    }

    section article {
        flex-flow: column wrap;
    }

    section article figure {
        width: 100%;
    }


    section article figure.four {
        width: 100%;
    }

    .form {
        flex-flow: column wrap;
    }

    .form .btn {
        padding: 0.5rem 2rem;
        font-size: 1.4rem;
    }

}