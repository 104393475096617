section article {
    padding: 0rem;
}

.admin-card{
    max-height: 50vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
    
}

.containerItemAdmin{
    background-color: rgb(184, 184, 184);
    padding: 1rem;
    margin: 1rem;
    width: 30%;
    position: relative;
    word-wrap: break-word;
}

.containerItemAdmin p{
    color: rgb(49, 49, 49);
}

.event{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.trash{
    color: rgb(221, 6, 6);
}


.trash:hover, .modify:hover, .close:hover{
    cursor: pointer;
    color: #FF896C;
}

.modify{
    color: #353096;
}


.searchBar{
    padding: 0.4rem;
}

.fixed-logout{
    position: fixed;
    bottom: 1%;
    right: 1%;
}

input[type=file]{
    margin-top: 1rem;
    border: none
}

input[type=file]:hover{
    cursor: pointer;
}

#article-valide{
    display: none;
}

/* --- Pop-up modify --- */
.id_none{
    display: none;
}
#modifyopen{
    position: fixed;
    z-index: 900;
    background-color: #F5F5F5;
    overflow-y: scroll;
    scrollbar-width: thin;
    margin: auto;
    top: 10%;
    left: 25%;
    width: 50%;
    height: auto;
    padding: 2rem;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
    
}

#modifyclose{
    display: none;
}

.close{
    position: absolute;
    top: 5%;
    right: 5%;
    color: #353096;
    font-size: 1.5rem;
}


/* --- End Pop-up modify --- */

/* --- Article en attente --- */

.toValidated{
    position: absolute;
    top:5%;
    right:0;
}

.toValidated .icone{
    display: inline;
    margin-right: 1rem;
    font-size: 1.5rem;
}

.toValidated .icone.validated{
    color: green;
}

.toValidated .icone.not-validated{
    color: red;
}

.toValidated .icone.not-validated:hover{
    border-bottom: 0.2rem solid red;
}

.toValidated .icone.validated:hover{
    border-bottom: 0.2rem solid green;
}

/* --- End Article en attente --- */

/* --- Partie des chefs de section --- */

.doc{
    display: flex;
    flex-flow: row wrap;
}

.doc li{
    width: 45%;
}

.doc li .icone{
    display: inline-block;
    margin: 1rem;
    color: #353096;
}

.doc li a{
    color: gray;
}

.doc li .icone:hover, .doc li:hover .icone, .doc li:hover a{
    color: #FF896C;
}

/* --- End Partie des chefs de section --- */

@media only screen and (max-width: 1200px) {
    .containerItemAdmin{
        width: 40%;
    }
}

@media only screen and (max-width: 900px) {
    section article {
        padding: 1rem;
    }

    .containerItemAdmin, .doc li{
        width: 100%;
    }

    #modifyopen{
        left: 10%;
        width: 80%;
        
    }

}