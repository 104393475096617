.blog-article{
    margin-bottom: 2rem;
}

.blog-article .containerItemAdmin{
    border: 0.1rem solid #353096;
    border-radius: 10px;
    padding-left: 2rem;
    width: 100%;
    background-color: white !important;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
}


.infos-pratique{
    margin-top:1rem;
}

.infos-pratique, .infos-pratique li{
    display: flex;
    flex-flow: row wrap;
    padding-left: 0rem !important;
}
li{
    list-style: none;
}
.infos-pratique li{
    margin-right: 1.4rem;
    font-family: bilo, sans-serif;
}

.infos-pratique li .icone{
    margin-right: 0.3rem;
    color: #353096;
    
}

@media only screen and (max-width: 700px) {
    .blog-article{
        flex-flow: column wrap;
    }

    .blog-article >div:first-child,.blog-article >div:last-child{
        width: 100%;
    }

    section article {
        margin-left: 0rem;
    }

}