a#btn-bis{
    padding: 0.5rem 1.5rem !important;
    font-size: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.7rem;
    color: #353096;
    border-radius: 8px;
    border: 0.1rem solid #353096;
}

a#btn-bis:hover{
    color: #FF896C;
    border: 0.1rem solid #FF896C;
}