@import url(https://use.typekit.net/rcn8nzq.css);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.html {
    background-color: rgba(204, 204, 204, 0.29);
    overflow-x: hidden; 
}

h2,
h3,
h4,
p, li {
    color: grey;
}

h1#main-title {
    font-size: 8rem;
    line-height: 9rem;
    font-family: bilo, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3rem;
}

h1 {
    font-size: 5rem;
    line-height: 7rem;
    font-family: bilo, sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3rem;
}

h2 {
    font-size: 3.5rem;
    line-height: 3.7rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 2rem;
}

h3 {
    font-size: 2rem;
    line-height: 2rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    margin-bottom: 2rem;
}

h4 {
    font-size: 1.7rem;
    line-height: 2rem;
    font-family: bilo, sans-serif;
    font-weight: 400;
    margin-bottom: 0.7rem;
}

p,
.list-item,
label, #btn-subscribe {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-family: bilo, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: gray;
    padding-bottom: 0.5rem;
}

.list-item {
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.list-item li {
    padding-bottom: 0.4rem;
}

a:hover {
    cursor: pointer;
}

.btn-text {
    color: #353096;
    border: none;
    font-family: bilo, sans-serif;
}

a {
    text-decoration: none;
}


.btn-text:hover {
    color: #FF896C;
    border-bottom: 0.1rem solid #FF896C;
}

.btn {
    font-size: 1.8rem;
    line-height: 1.7rem;
    font-family: bilo, sans-serif;
    font-weight: 300;
    color: white;
    background-color: #353096;
    padding: 0.5rem 3.7rem;
    border-radius: 8px;
}

.btn:hover {
    color: black;
    background-color: #FF896C;
}

.banner-text {
    text-align: center;
    font-weight: 300;
    padding-top: 5rem;
}

.text-center {
    text-align: center;
}

section {
    margin-bottom: 5rem;
}


/* ---  Header --- */

#hamburger {
    display: none;
    width: 2rem;
    height: 1.7rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
}

.burger {
    display: none;
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: gray !important;
    -webkit-transform-origin: 1px;
            transform-origin: 1px;
    transition: all 0.3s linear;
}

header#navArea {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-family: bilo, sans-serif;
    padding-top: 3rem;
    position: fixed;
    left: 10%;
    width: 80%;
    transition: background-color 7s;
    transition: margin-top 7s;
}

#slogan {
    color: white;
    font-weight: 500;
}

header figure p {
    font-size: 1rem;
}

header figure a {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
}

header figure img {
    height: 8rem;
    width: auto;
}

header ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    max-width: 700px;
}

.nav-reseau {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    /* padding-left: 5%; */
}

.nav-reseau img {
    width: 3rem;
}

.nav-reseau a:nth-child(2) img{
    width: 3.7rem;
}

.nav-reseau a:nth-child(3) {
    margin-top: 0.3rem;
    margin-left: 0.8rem;
}

header ul li {
    list-style: none;
}

header ul li {
    position: relative;
}

header ul li.deroulant::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid white;
    position: absolute;
    top: 40%;
    right: -12px;
}

#navArea.is-sticky ul li.deroulant::after {
    border-top: 8px solid gray !important;
}

.hamburgerbox, .popupModifybox {
    display: none;
}

.sous .icone {
    display: inline;
    font-size: 1rem;
    margin-right: 0.5rem;
}

header ul li a {
    margin-top: 0.5rem;
}

header#navArea ul li a::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: white;
    transition: width .3s;
}

header#navArea.is-sticky ul li a::after {
    background: gray;
}

header#navArea ul li:hover a::after {
    width: 110%;
}

header#navArea ul.sous li a::after,header#navArea ul li.nav-reseau a::after {
    content: '';
    display: none;
}
/* sous menu */

#sectiononpen {
    display: block;
}

#sectionclose {
    display: none;
}

li a.deroulant {
    position: relative;
}

#adminopen,
#sectionopen {
    display: block;
    display: flex;
    flex-flow: column wrap !important;
    align-items: baseline;
    position: absolute;
    width: 15rem !important;
    border-radius: 10px;
    margin-top: 1rem;
    margin-left: -3.2rem;
    background-color: white;
    z-index: 300;
    -webkit-animation: scrollMenu 0.2s ease-in-out;
            animation: scrollMenu 0.2s ease-in-out;
    opacity: 1;
}

#adminopen::before,
#sectionopen::before {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: white;
    position: absolute;
    top: -7px;
    left: 35%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    z-index: -1;
}

#adminopen li,
#sectionopen li {
    padding: 1rem;
    width: 100%;
}

#adminopen li:hover,
#sectionopen li:hover {
    background-color: #35309639;
}

#adminclose {
    display: none;
}


header #navclose li a {
    text-decoration: none;
    color: white;
    font-weight: 400;
}

header #navclose li a.sous-link {
    text-decoration: none;
    color: gray;
}


/* fin sous menu */

/* menu scroll */
#navArea{
    display: block;
}

@-webkit-keyframes scrollMenu{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@keyframes scrollMenu{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
#navArea.is-sticky {
    -webkit-animation: scrollMenu 0.4s ease-in-out;
            animation: scrollMenu 0.4s ease-in-out;
     z-index: 300;
    background-color: rgb(244, 244, 244);
    margin-top: 0rem;
    position: fixed;
    width: 100vw;
    padding: 1% 15%;
    left: 0;
    opacity: 1;
    box-shadow: 0px 4px 12px 5px rgba(0,0,0,0.2);
} 

#navArea.is-sticky a {
    color: gray;
}

#navArea.is-sticky img {
    height: 5rem;
    width: auto;
}

#navArea.is-sticky .nav-reseau a img {
    width: 3rem !important;
}

#navArea.is-sticky .nav-reseau a:nth-child(2) img {
    width: 3.7rem !important;
}

/* fin menu scroll */


/* ---  Fin Header --- */

/* ---  Main --- */

main {
    width: 75%;
    margin: auto;
}

/* ---  Section-Home --- */


.homepage {
    padding: 15rem 10rem;
    min-height: 100vh !important;
    background-image: url(../../static/media/pilotis.ab0913a7.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 40%;
    text-align: center;
    color: white;
    margin-bottom: 0;
}

/* cloud */

#cloud{
    display: none;
    
}
/* fin cloud */

.homepage h1 em {
    color: rgb(180, 180, 180);
    font-style: normal;
}

.homepage p em,
.section-home p em {
    font-family: bilo, sans-serif;
    font-weight: 100;
    font-style: normal;
    font-size: 2rem;
    color: white;
}

/* ---  Fin Section-Home --- */

/* ---  Fin Section-Sections-Valeurs --- */

section article {
    padding: 1rem;
}

section article figure {
    padding: 1rem;
}

img {
    width: 15rem;
    height: auto;
}

section article {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: strech;
    margin-bottom: 0.5rem;
}

.background-white {
    background-color: white;
    margin: 1rem;
}

section article figure {
    width: 30%;
}

section article figure.four {
    width: 20%;
}

section article figure {
    text-align: center;
}

section article figure div.image-background {
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 1rem;
}

section article figure:nth-child(1) div.image-background {
    background-image: url(../../static/media/respect.e2dcb0e0.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

section article figure:nth-child(2) div.image-background {
    background-image: url(../../static/media/entraide.7947fd4c.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

section article figure:nth-child(3) div.image-background {
    background-image: url(../../static/media/proactive.d6141ef7.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.section-sections article figure {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.section-sections article figure:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

/* ---  Fin Main --- */


/* ---  Footer --- */

footer {
    background-color: white;
    text-align: center;
    padding: 2.3rem 7rem;
}

/* ---  Fin Footer --- */

@media only screen and (max-width: 1300px) {
    header {
        left: 7%;
        width: 90%;
    }
    header#navArea{
        width: 100vw;
        padding: 1% 5% !important;
        left: 0;
    }
    header#navArea.is-sticky {
        z-index: 300;
        background-color: rgb(244, 244, 244);
        width: 100vw;
        padding: 1% 5% !important;
        left: 0;
    }
   
}

@media only screen and (max-width: 1000px) {
    main {
        width: 90%;
    }
    .hamburgerbox,
    .hamburger,
    .burger {
        display: block;
    }

    #hamburger {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
        margin-right: 1rem;
    }

    .hamburger {
        padding-top: 10px;
        margin-right: 10px;
        z-index: 600;
    }

    header {
        position: absolute;
    }


    #navopen {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        width: 100vw;
        margin-top: 0px;
        border-radius: 10px;
        padding: 6rem 1.5rem;
        color: gray;
        font-size: 2rem;
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    #navopen ul {
        height: 70vh;
    }

    header ul li {
        padding: 1rem;
    }

    header ul.sous li {
        padding: 1rem;
        width: 80vw;
        border-bottom: none !important;
    }

    header ul li {
        list-style: none;
    }

    #navclose {
        display: none;
    }

    header ul li.deroulant::after {
        top: 50%;
        right: 20%;
    }

    ul li.deroulant::after {
        border-top: 8px solid gray !important;
    }

    #navArea.is-sticky ul li:nth-child(2)::after,
    #navArea.is-sticky ul li:nth-child(4)::after {
        border-top: 8px solid gray !important;
    }

    li:last-child{
        border-bottom: none !important;
    }
    /* sous menu */
    
    #adminopen,
    #sectionopen {
        display: block;
        display: flex;
        flex-flow: column wrap !important;
        align-items: baseline;
        position: inherit;
        width: 90% !important;
        padding:0.2rem;
        font-size: 1.2rem;
        height: auto;
        left: 5%;
        right: 5%;
        margin: 0;
    }


    #adminopen::before,
    #sectionopen::before {
        display: none;
    }
    
    #adminopen li,
    #sectionopen li {
        padding: 1rem;
        width: 100%;
    }
    
    #adminopen li:hover,
    #sectionopen li:hover {
        background-color: #35309639;
    }

        
    header #navclose li a {
        text-decoration: none;
        color: white;
        font-weight: 400;
    }
    
    header #navclose li a.sous-link {
        text-decoration: none;
        color: gray;
    }
    
    header .sous li a::after {
        content: '';
        display: none;
    }
    /* fin sous menu */
}

@media only screen and (max-width: 700px) {
    main {
        width: 92%;
    }

    header#navArea {
        margin-top: 0rem;
        padding: 1rem;
        width: 90%;
        left: 5%;
    }

    #slogan {
        display: none;
    }


    h1#main-title {
        font-size: 3rem;
        line-height: 4rem;
        margin-bottom: 3rem;
    }

    .homepage {
        padding: 12rem 2rem;
        background-size: cover;
    }

    #navopen {
        position: fixed;
        top: 0;
        left: 0;
        font-size: 2rem;
        height: 100vh;
        width: 100%;
        right: 0;
        text-align: center;
        overflow-y: scroll;
        scrollbar-width: thin;
    }

    header ul {
        padding: 6rem 0.2rem;
    }

    header ul li {
        border-bottom: 0.1rem solid #353096 !important;
        padding: 1.4rem 0;
    }

    header ul li:last-child {
        border-bottom: none !important;
        padding: 1.4rem 0;
    }

    header ul li a {
        color: gray;
    }

    section article {
        flex-flow: column wrap;
    }

    section article figure {
        width: 100%;
    }


    section article figure.four {
        width: 100%;
    }

    .form {
        flex-flow: column wrap;
    }

    .form .btn {
        padding: 0.5rem 2rem;
        font-size: 1.4rem;
    }

}
/* --- Section Home --- */

.home-nuton{
    background-image: url(../../static/media/nutonsImg.296fabe6.jpg);
}

.home-lutin{
    background-image: url(../../static/media/enfants.136c99ee.jpg);
}

.home-guide{
    background-image: url(../../static/media/fire.461ecca9.jpg);
}

.home-pi{
    background-image: url(../../static/media/chain.d3c8390b.jpg);
}

.home-subscribe{
    background-image: url(../../static/media/subscribe.667ac585.jpg) !important;
    background-position: 50% 100% !important; 
}

.home-blog{
    background-image: url(../../static/media/newspaper.0814941a.jpg) !important;
    background-position: 50% 100% !important; 
}

.section-home{
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    height: 80vh;
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}


.home-subscribe{
    background-image: url(../../static/media/chain.d3c8390b.jpg);
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    height: 50vh;
    width: 100vw !important;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
}

.section-home h1{
    color: white;
    font-size: 8rem;
    text-shadow: 1px 1px 1px rgb(182, 182, 182), 0 0 1em rgb(182, 182, 182) ;
    text-align: center;
}

.section-home h2{
    color: white;
}

/* --- Fin Section Home --- */

/* --- Section Intro --- */

.intro-nuton{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.intro-nuton article:nth-child(1){
    width: 35%;
}

.intro-nuton article:nth-child(2){
    width: 60%;
}

/* --- Fin Section Intro --- */

/* --- Section Staff --- */

.section-staff ul{
    display: flex;
    flex-flow: row wrap;
}

.section-staff ul li{
    width: 45%;
}

/* --- Fin Section Staff --- */

.icone-section{
    color: gray;
    font-size: 6rem;
    color: #eec094;
    margin-bottom: 1rem;
}

.icone-scout{
    color:#9aca9a;
}

.icone-loup{
    color:#ed7d79;
}

/* --- Rendez-vous --- */

#activity-mobile{
    display: none;
}

.activity{
    display: flex;
    flex-flow: row wrap;
    justify-content:space-between;
}

.activity:hover{
    background-color: white;
}

.activity h3, .activity p{
    width: 32%;
    text-align: center;
}

@media only screen and (max-width: 740px) {

    .section-home h1 {
        font-size: 5rem;
        text-align: center;
    }

    .intro-nuton article{
        width: 100% !important;
        flex-flow: row wrap;
    }

    section article{
        margin-left: -2rem;
    }

    #activity-mobile{
        display: block;
    }

    #activity-desktop{
        display: none;
    }

    .activity.list-item{
        display: block;
        border-bottom: 0.1rem solid gray;
        margin-left:0;
    }

    .activity.list-item li{
        list-style: none;
        padding-left: 0.5rem;
    }
}


/* ---  Subscribe --- */

.section-subscribe {
    width: 100% !important;
    padding: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    
}

.intro-subscribe{
    width: 45% !important;
}

section article.intro-subscribe{
    display: block !important;
}

.form {
    width: 45% !important;
    margin: auto;
}

.form .champ {
    display: flex;
    flex-flow: column wrap;
}

.form .champ:nth-child(3),
.champ:nth-child(4) {
    flex-grow: 1;
    width: 100% !important;
}

#display-mobile{
    display: none;
}

#display-desktop{
    display: block;
}

::-webkit-input-placeholder {
    font-family: bilo, sans-serif;
    font-size: 1rem;
    color: rgb(177, 177, 177);
}

:-ms-input-placeholder {
    font-family: bilo, sans-serif;
    font-size: 1rem;
    color: rgb(177, 177, 177);
}

::placeholder {
    font-family: bilo, sans-serif;
    font-size: 1rem;
    color: rgb(177, 177, 177);
}


a#btn-subscribe {
    padding: 0.5rem 1.5rem !important;
    font-size: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.7rem;
    color: white;
    background-color: #353096;
    border-radius: 8px;
}

a#btn-subscribe:hover{
    background-color: #FF896C; 
}


input,
select,
textarea {
    border: 0.1rem solid grey;
    background-color: white !important;
    color: grey;
    border-radius: 3px;
    padding: 0.2rem;
}

label {
    display: inline-block;
    text-align: left;
    padding: 0 0.5em 0.5rem 0;
    margin-top: 1rem;

}

label:hover {
    cursor: pointer;
}


input[type=submit] {
    /* cible le bouton input de type submit pour l'alignement à droite */
    float: right;
    border-style: solid;
    border-width: 1px;
    border-color: #002F2F;
}

input[type=text],
input[type=email],
input[type=number],
input[type=date] {
    width: 100%;
}

/*------------ Gestion du placeholder ----------------- */


/* Ok pour FFx, Chrome, Safari et Opéra */
input::-webkit-input-placeholder {
    color: #353096;
    font-style: italic;
}
input:-ms-input-placeholder {
    color: #353096;
    font-style: italic;
}
input::placeholder {
    color: #353096;
    font-style: italic;
}


label.obligatoire::before {
    content: "* ";
    /* ajout automatique d'un astérisque devant les champs obligatoires */
    color: #353096;
}


/* --------------------- Gestion des éléments réactifs au clic ----------------------------- */

input:hover,
input:focus,
select:hover {
    outline: 0.1rem solid #353096;
    /* La propriété outline css ne réagissent pas comme les bordures, ces contours viennent au dessus de l'élément et n'interviennent pas dans la taille de l'élément. */
}



/* --------------------- Gestion des éléments invalides --------------------------- */

/*La pseudo-classe :invalid représente tout élément <input> dont la validation du contenu échoue en regard du type de donné attendu. Ceci permet de donner aisément une apparence aux champs non valides aidant l'utilisateur à identifier et corriger les erreurs. */

input:invalid {
    background-color: orange;
}

/*La pseudo-classe CSS :valid représente tout élément <input> dont la validation du contenu s'effectue correctement en regard du type de donné attendu. Ceci permet de donner aisément une apparence aux champs de formulaire correctement remplis par l'utilisateur.(Webkit) */

input:valid {
    background-color: silver;
}


/* ---  Fin Subscribe --- */

@media only screen and (max-width: 900px) {

    #display-mobile{
        display: block;
        padding-top: 2rem;
    }

    #display-desktop{
        display: none;
    }

    .home-subscribe h1{
        font-size: 4rem;
    }

    .section-subscribe {
        flex-flow: column wrap;
        
    }

    section.home-subscribe{
        margin: 0;
    }
    
    .form, .intro-subscribe {
        width: 100% !important;
    }

    section article.intro-subscribe{
        margin-left: 0rem;
    }
}


section article {
    padding: 0rem;
}

.admin-card{
    max-height: 50vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
    
}

.containerItemAdmin{
    background-color: rgb(184, 184, 184);
    padding: 1rem;
    margin: 1rem;
    width: 30%;
    position: relative;
    word-wrap: break-word;
}

.containerItemAdmin p{
    color: rgb(49, 49, 49);
}

.event{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.trash{
    color: rgb(221, 6, 6);
}


.trash:hover, .modify:hover, .close:hover{
    cursor: pointer;
    color: #FF896C;
}

.modify{
    color: #353096;
}


.searchBar{
    padding: 0.4rem;
}

.fixed-logout{
    position: fixed;
    bottom: 1%;
    right: 1%;
}

input[type=file]{
    margin-top: 1rem;
    border: none
}

input[type=file]:hover{
    cursor: pointer;
}

#article-valide{
    display: none;
}

/* --- Pop-up modify --- */
.id_none{
    display: none;
}
#modifyopen{
    position: fixed;
    z-index: 900;
    background-color: #F5F5F5;
    overflow-y: scroll;
    scrollbar-width: thin;
    margin: auto;
    top: 10%;
    left: 25%;
    width: 50%;
    height: auto;
    padding: 2rem;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
    
}

#modifyclose{
    display: none;
}

.close{
    position: absolute;
    top: 5%;
    right: 5%;
    color: #353096;
    font-size: 1.5rem;
}


/* --- End Pop-up modify --- */

/* --- Article en attente --- */

.toValidated{
    position: absolute;
    top:5%;
    right:0;
}

.toValidated .icone{
    display: inline;
    margin-right: 1rem;
    font-size: 1.5rem;
}

.toValidated .icone.validated{
    color: green;
}

.toValidated .icone.not-validated{
    color: red;
}

.toValidated .icone.not-validated:hover{
    border-bottom: 0.2rem solid red;
}

.toValidated .icone.validated:hover{
    border-bottom: 0.2rem solid green;
}

/* --- End Article en attente --- */

/* --- Partie des chefs de section --- */

.doc{
    display: flex;
    flex-flow: row wrap;
}

.doc li{
    width: 45%;
}

.doc li .icone{
    display: inline-block;
    margin: 1rem;
    color: #353096;
}

.doc li a{
    color: gray;
}

.doc li .icone:hover, .doc li:hover .icone, .doc li:hover a{
    color: #FF896C;
}

/* --- End Partie des chefs de section --- */

@media only screen and (max-width: 1200px) {
    .containerItemAdmin{
        width: 40%;
    }
}

@media only screen and (max-width: 900px) {
    section article {
        padding: 1rem;
    }

    .containerItemAdmin, .doc li{
        width: 100%;
    }

    #modifyopen{
        left: 10%;
        width: 80%;
        
    }

}
a#btn-bis{
    padding: 0.5rem 1.5rem !important;
    font-size: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.7rem;
    color: #353096;
    border-radius: 8px;
    border: 0.1rem solid #353096;
}

a#btn-bis:hover{
    color: #FF896C;
    border: 0.1rem solid #FF896C;
}
.blog-article{
    margin-bottom: 2rem;
}

.blog-article .containerItemAdmin{
    border: 0.1rem solid #353096;
    border-radius: 10px;
    padding-left: 2rem;
    width: 100%;
    background-color: white !important;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);
}


.infos-pratique{
    margin-top:1rem;
}

.infos-pratique, .infos-pratique li{
    display: flex;
    flex-flow: row wrap;
    padding-left: 0rem !important;
}
li{
    list-style: none;
}
.infos-pratique li{
    margin-right: 1.4rem;
    font-family: bilo, sans-serif;
}

.infos-pratique li .icone{
    margin-right: 0.3rem;
    color: #353096;
    
}

@media only screen and (max-width: 700px) {
    .blog-article{
        flex-flow: column wrap;
    }

    .blog-article >div:first-child,.blog-article >div:last-child{
        width: 100%;
    }

    section article {
        margin-left: 0rem;
    }

}
